import type { ICities, ICompaniesRating, ICompanyData, ICompanyTypes, TWordForm } from '~/types/rating'

export function useRating() {
  const route = useRoute()
  const { public: { BASE_API_URL } } = useRuntimeConfig()
  const apiUrl = useCookie('api').value || BASE_API_URL
  const companyType = ref<number | null>(null)
  const city = ref<number | null>(null)
  const cities = ref<null | ICities[]>(null)
  const order = ref<boolean>(false)
  const isLoading = ref<boolean>(true)
  const search = ref<string>('')
  const wordForms = reactive({
    city: {},
    type: {},
  })
  const companyTypes = ref<null | ICompanyTypes[]>(null)
  const companiesRating = ref<object | ICompaniesRating>({})
  const currentPage = ref<null | number>(2)
  const canPaginate = ref(true)

  const switchOrder = () => order.value = !order.value
  async function fetchOrganizations(showNextPage: boolean = false) {
    if (city.value && companyType.value) {
      const body = search.value.length < 1
        ? {
            type_id: companyType.value,
            city_id: city.value,
            order: Number(order.value),
          }
        : {
            type_id: companyType.value,
            city_id: city.value,
            order: Number(order.value),
            name: search.value,
          }
      isLoading.value = true
      try {
        const request = await $fetch(showNextPage ? `rating/organizations?page=${currentPage.value}` : `rating/organizations`, {
          method: 'POST',
          body,
          baseURL: apiUrl,
        })
        if (showNextPage && canPaginate.value) {
          companiesRating.value.data = companiesRating.value.data.concat(request.data)
          companiesRating.value.meta = request.meta
          if (currentPage.value === Number(request.meta.links[request.meta.links.length - 2].label)) {
            canPaginate.value = false
          }
          else {
            currentPage.value += 1
          }
        }
        else {
          companiesRating.value = request
        }
      }
      catch (e) {
        console.error(e)
      }
      isLoading.value = false
    }
  }
  async function fetchTypes(city_id?: number) {
    isLoading.value = true
    try {
      companyTypes.value = await $fetch(`/rating/${city_id || city.value}/rating-types`, {
        method: 'GET',
        baseURL: apiUrl,
      })
    }
    catch (e) { console.error(e) }
    isLoading.value = false
  }
  async function fetchCities() {
    isLoading.value = true
    try {
      cities.value = await $fetch(`/rating/cities`, {
        method: 'GET',
        baseURL: apiUrl,
      })
    }
    catch (e) { console.error(e) }
    isLoading.value = false
  }
  async function fetchOrganization(slug?: string): Promise<ICompanyData> {
    const request = await $fetch<ICompanyData>(`/rating/organization`, {
      method: 'POST',
      body: {
        slug: slug || route.params.slug[0],
      },
      baseURL: apiUrl,
    })
    let link = request.organization.website

    if (typeof link === 'string') {
      // check and remove http/https
      if (link.startsWith('http://')) {
        link = link.slice(7)
      }
      else if (link.startsWith('https://')) {
        link = link.slice(8)
      }
      // check and remove www
      if (link.startsWith('www.')) {
        link = link.slice(4)
      }
      request.organization.website = link.split('/')[0]
    }
    return request
  }
  async function fetchWordForms(argCity: string, argType: string) {
    isLoading.value = true

    const result = {
      city: {} as TWordForm,
      type: {} as TWordForm,
    }
    result.city = await $fetch(`/rating/${argCity || city.value}/word-forms`, {
      method: 'GET',
      baseURL: apiUrl,
    })
    result.type = await $fetch(`/rating/${argCity || city.value}/word-forms/${argType || companyType.value}`, {
      method: 'GET',
      baseURL: apiUrl,
    })

    isLoading.value = false
    return result
  }

  return {
    isLoading,
    city,
    cities,
    companyType,
    companyTypes,
    wordForms,
    search,
    order,
    switchOrder,
    companiesRating,
    fetchOrganizations,
    fetchTypes,
    fetchCities,
    fetchOrganization,
    fetchWordForms,
    canPaginate,
  }
}
